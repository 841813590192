<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="lock"
                class="kt-svg-icon mt-2 mr-3"
            />
            Data Encryption
        </template>
        <template #left-buttons>
            <span
                v-b-tooltip.hover="`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`"
                class="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2"
                style="max-height: 30px; align-self: center;"
            >
                <i class="fas fa-fire mr-2" />
                <span class="kt-font-boldest">BETA</span>
            </span>
        </template>
        <template #buttons>
            <!-- not -->
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <!-- first time setup -->
        <div v-if="!serverSideEncryptionEnabled">
            <div v-if="canEnableEncryption" class="row mr-0 ml-0 w-100">
                <div
                    class="alert alert-light alert-elevate fade show w-100"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        By choosing a School Term Encryption Password, you are enabling an additional layer of encryption to protect
                        student personal identifiable information (PII) in our data systems at rest.
                        <p class="kt-font-bolder pt-3">
                            All Staff who need to access student PII, will need to know this password.
                        </p>
                        This third factor of authentication and resulting encryption keys are intentionally not stored by our systems.
                        Please choose a memorable high entrophy password.
                    </div>
                </div>
            </div>
            <div v-if="canEnableEncryption" class="row mr-0 ml-0 w-100">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 v-if="isNewPassword" class="kt-portlet__head-title">
                                Setup School Term Encryption
                            </h3>
                        </div>
                    </div>
                    <form class="kt-form">
                        <div class="kt-portlet__body">
                            <div class="form-group">
                                <label>School Term Encryption Password</label>
                                <input
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    :name="randomizeFormName"
                                    :class="{'is-invalid': !isValidSchoolPassword}"
                                    autocomplete="password"
                                >
                                <span class="form-text text-muted">
                                    Please choose a password to encrypt and decrypt the data for this school term.
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Confirm School Term Encryption Password</label>
                                <input
                                    v-model="confirmPassword"
                                    type="password"
                                    class="form-control"
                                    :name="randomizeFormName"
                                    :class="{'is-invalid': !passwordMatch}"
                                    autocomplete="password"
                                >
                                <span class="form-text text-muted">
                                    Please confirm the password.
                                </span>
                            </div>
                        </div>
                        <div class="kt-portlet__foot mb-5">
                            <div class="kt-form__actions">
                                <button
                                    type="button"
                                    class="btn btn-info pull-right"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                    @click="setSchoolTermEncryptionKey"
                                >
                                    Set Encryption Password
                                </button>
                                <button
                                    v-if="isSetupWizard && step.status == 'danger'"
                                    type="button"
                                    class="btn btn-secondary pull-right mr-2"
                                    @click="skipSetupWizard"
                                >
                                    Skip this Step
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- unlock client side -->
        <template v-else-if="clientSideEncryptionEnabled && !isSetupWizard">
            <div class="col col-lg-6 offset-lg-3 pt-5">
                <div class="kt-portlet kt-bg-danger kt-portlet--skin-solid kt-portlet--height-fluid">
                    <div class="kt-portlet__body">
                        <div class="kt-widget7 kt-widget7--skin-light">
                            <div class="kt-widget7__desc my-3">
                                Encryption is currently enabled for this school term.
                                To view student portfolio PII during this browsing session, you must first supply the encryption key via
                                the lock icon in the upper right, or by clicking the button below.

                                To modify settings, you must first unlock the encryption.
                            </div>
                            <div class="kt-widget7__content">
                                <div class="kt-widget7__userpic">
                                    <img src="/images/lock.svg" style="width: 260px; min-width: 200px;">
                                </div>
                            </div>
                            <div class="kt-widget7__button">
                                <button
                                    type="button"
                                    style="min-width: 260px; margin: auto;"
                                    class="btn btn-primary btn-lg kt-font-lg btn-upper mt-3"
                                    @click="startDecryption"
                                >
                                    Unlock
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- remove encryption -->
        <div v-else>
            <div class="alert alert-success fade show" role="alert">
                <div class="alert-icon">
                    <i class="flaticon-questions-circular-button" />
                </div>
                <div class="alert-text">
                    Encryption is currently unlocked for this browsing session.
                </div>
            </div>
            <div v-if="!isSetupWizard" class="col col-lg-6 offset-lg-3 pt-5">
                <div class="kt-portlet kt-bg-warning kt-portlet--skin-solid kt-portlet--height-fluid">
                    <div class="kt-portlet__body">
                        <div class="kt-widget7 kt-widget7--skin-light">
                            <div class="kt-widget7__desc my-3">
                                Remove encryption for this school term by clicking the button below.
                                This will remove the encryption key and all data will be decrypted for this term for all staff.
                            </div>
                            <div class="kt-widget7__content">
                                <div class="kt-widget7__userpic">
                                    <img src="/images/lock.svg" style="width: 260px; min-width: 200px;">
                                </div>
                            </div>
                            <div class="kt-widget7__button">
                                <button
                                    type="button"
                                    style="min-width: 260px; margin: auto;"
                                    class="btn btn-danger btn-lg kt-font-lg btn-upper mt-3"
                                    @click="removeEncryption"
                                >
                                    Remove School Term Encryption
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import * as network from '../network';
import Types from '../store/Types';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';

export default Vue.extend({
    name: 'DataEncryption',
    components: {
        GeneralSubHeader,
    },
    data() {
        return {
            isNewPassword: true,
            saving: false,
            password: '',
            newPassword: '',
            confirmPassword: '',
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        hasEncryptionCredentials() {
            return Boolean(this.$store.state.database.schoolTermEncryptionKey);
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.user.school.serverSideEncryptionEnabled;
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps
                .find((step) => step.routeName == 'setup-encryption');
        },
        encryptionHash() {
            return this.user.school.encryptionHash;
        },
        randomizeFormName() {
            return `randomized-${Math.random().toString(36).substring(7)}`;
        },
        isValidSchoolPassword() {
            const password = this.password || '';
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
            if (password.length <= 8) return false;
            if (!/\d/.test(password)) return false;
            if (!/[A-Z]/.test(password)) return false;
            if (!format.test(password)) return false;
            return true;
        },
        passwordMatch() {
            return this.password && this.password === this.confirmPassword;
        },
        formIsValid() {
            return this.isValidSchoolPassword && this.passwordMatch;
        },
        hasEncryptionHash() {
            return Boolean(this.user.school.encryptionHash);
        },
        canEnableEncryption() {
            return this.user.twoFactorAuth
                && this.user.twoFactorAuth.twoFactorAuthEnabled
                && this.$store.state.user.hasLocalPassword;
        },

    },
    mounted() {
        if (this.$store.state.user.school.externalSchoolId == '00X000') {
            this.password = 'Welcome1!';
            this.confirmPassword = 'Welcome1!';
        }
    },
    methods: {
        skipSetupWizard() {
            const { step } = this;
            this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, { routeName: 'setup-encryption', value: 'true' });
        },
        startDecryption() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        setSchoolTermEncryptionKey() {
            const { schoolTermId, schoolId } = this.$store.state.user.school;
            const { password, showError, formIsValid } = this;
            const { commit, dispatch } = this.$store;
            if (!formIsValid) return showError('Please enter a valid password and confirm it.');
            // if (!window.confirm('Are you sure you want to enable encryption for all users this school term?')) return;

            const params = {
                url: { schoolTermId, schoolId },
                body: { password },
            };
            network.cipher.setSchoolTermEncryptionPassword(params, (err, res) => {
                if (err) return showError(err);
                if (res && res.exists) return showError('A password already exists for this school term.');

                const { encryptionHash } = res;

                commit(Types.mutations.SET_ENCRYPTION_HASH, encryptionHash);
                dispatch(Types.actions.ENABLE_SERVER_SIDE_ENCRYPTION, {
                    schoolPassword: password,
                    callback: (err2) => {
                        if (err2) return showError(err2);
                        commit(Types.mutations.SET_SERVER_SIDE_ENCRYPTION_ENABLED, true);
                        window.location.reload();
                    },
                });
            });
        },
        removeEncryption() {
            const { showError } = this;
            if (!this.$store.state.database.schoolTermEncryptionKey) {
                return showError('Please unlock before continuing.');
            }
            if (!window.confirm('Are you sure you want to remove encryption for all users this school term?')) return;

            const { commit, dispatch } = this.$store;
            dispatch(Types.actions.DISABLE_SERVER_SIDE_ENCRYPTION, {
                callback: (err) => {
                    if (err) return showError(err);
                    commit(Types.mutations.SET_ENCRYPTION_HASH, null);
                    commit(Types.mutations.SET_SERVER_SIDE_ENCRYPTION_ENABLED, false);
                    window.location.reload();
                },
            });
        },
    },
});
</script>
