var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-2 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "lock" },
                }),
                _vm._v(" Data Encryption "),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        value: `This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`,
                        expression:
                          "`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass:
                      "kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2",
                    staticStyle: {
                      "max-height": "30px",
                      "align-self": "center",
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-fire mr-2" }),
                    _c("span", { staticClass: "kt-font-boldest" }, [
                      _vm._v("BETA"),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          !_vm.serverSideEncryptionEnabled
            ? _c("div", [
                _vm.canEnableEncryption
                  ? _c("div", { staticClass: "row mr-0 ml-0 w-100" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
                _vm.canEnableEncryption
                  ? _c("div", { staticClass: "row mr-0 ml-0 w-100" }, [
                      _c("div", { staticClass: "kt-portlet" }, [
                        _c("div", { staticClass: "kt-portlet__head" }, [
                          _c("div", { staticClass: "kt-portlet__head-label" }, [
                            _vm.isNewPassword
                              ? _c(
                                  "h3",
                                  { staticClass: "kt-portlet__head-title" },
                                  [_vm._v(" Setup School Term Encryption ")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("form", { staticClass: "kt-form" }, [
                          _c("div", { staticClass: "kt-portlet__body" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v("School Term Encryption Password"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password,
                                    expression: "password",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": !_vm.isValidSchoolPassword,
                                },
                                attrs: {
                                  type: "password",
                                  name: _vm.randomizeFormName,
                                  autocomplete: "password",
                                },
                                domProps: { value: _vm.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.password = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    " Please choose a password to encrypt and decrypt the data for this school term. "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  "Confirm School Term Encryption Password"
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.confirmPassword,
                                    expression: "confirmPassword",
                                  },
                                ],
                                staticClass: "form-control",
                                class: { "is-invalid": !_vm.passwordMatch },
                                attrs: {
                                  type: "password",
                                  name: _vm.randomizeFormName,
                                  autocomplete: "password",
                                },
                                domProps: { value: _vm.confirmPassword },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.confirmPassword = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [_vm._v(" Please confirm the password. ")]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "kt-portlet__foot mb-5" }, [
                            _c("div", { staticClass: "kt-form__actions" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info pull-right",
                                  class: {
                                    "kt-spinner kt-spinner--sm kt-spinner--light":
                                      _vm.saving,
                                  },
                                  attrs: { type: "button" },
                                  on: { click: _vm.setSchoolTermEncryptionKey },
                                },
                                [_vm._v(" Set Encryption Password ")]
                              ),
                              _vm.isSetupWizard && _vm.step.status == "danger"
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary pull-right mr-2",
                                      attrs: { type: "button" },
                                      on: { click: _vm.skipSetupWizard },
                                    },
                                    [_vm._v(" Skip this Step ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.clientSideEncryptionEnabled && !_vm.isSetupWizard
            ? [
                _c("div", { staticClass: "col col-lg-6 offset-lg-3 pt-5" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-portlet kt-bg-danger kt-portlet--skin-solid kt-portlet--height-fluid",
                    },
                    [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c(
                          "div",
                          { staticClass: "kt-widget7 kt-widget7--skin-light" },
                          [
                            _c(
                              "div",
                              { staticClass: "kt-widget7__desc my-3" },
                              [
                                _vm._v(
                                  " Encryption is currently enabled for this school term. To view student portfolio PII during this browsing session, you must first supply the encryption key via the lock icon in the upper right, or by clicking the button below. To modify settings, you must first unlock the encryption. "
                                ),
                              ]
                            ),
                            _vm._m(1),
                            _c("div", { staticClass: "kt-widget7__button" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-lg kt-font-lg btn-upper mt-3",
                                  staticStyle: {
                                    "min-width": "260px",
                                    margin: "auto",
                                  },
                                  attrs: { type: "button" },
                                  on: { click: _vm.startDecryption },
                                },
                                [_vm._v(" Unlock ")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            : _c("div", [
                _vm._m(2),
                !_vm.isSetupWizard
                  ? _c(
                      "div",
                      { staticClass: "col col-lg-6 offset-lg-3 pt-5" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-portlet kt-bg-warning kt-portlet--skin-solid kt-portlet--height-fluid",
                          },
                          [
                            _c("div", { staticClass: "kt-portlet__body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-widget7 kt-widget7--skin-light",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget7__desc my-3" },
                                    [
                                      _vm._v(
                                        " Remove encryption for this school term by clicking the button below. This will remove the encryption key and all data will be decrypted for this term for all staff. "
                                      ),
                                    ]
                                  ),
                                  _vm._m(3),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget7__button" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-danger btn-lg kt-font-lg btn-upper mt-3",
                                          staticStyle: {
                                            "min-width": "260px",
                                            margin: "auto",
                                          },
                                          attrs: { type: "button" },
                                          on: { click: _vm.removeEncryption },
                                        },
                                        [
                                          _vm._v(
                                            " Remove School Term Encryption "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate fade show w-100",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " By choosing a School Term Encryption Password, you are enabling an additional layer of encryption to protect student personal identifiable information (PII) in our data systems at rest. "
          ),
          _c("p", { staticClass: "kt-font-bolder pt-3" }, [
            _vm._v(
              " All Staff who need to access student PII, will need to know this password. "
            ),
          ]),
          _vm._v(
            " This third factor of authentication and resulting encryption keys are intentionally not stored by our systems. Please choose a memorable high entrophy password. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget7__content" }, [
      _c("div", { staticClass: "kt-widget7__userpic" }, [
        _c("img", {
          staticStyle: { width: "260px", "min-width": "200px" },
          attrs: { src: "/images/lock.svg" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "alert alert-success fade show",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "flaticon-questions-circular-button" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " Encryption is currently unlocked for this browsing session. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget7__content" }, [
      _c("div", { staticClass: "kt-widget7__userpic" }, [
        _c("img", {
          staticStyle: { width: "260px", "min-width": "200px" },
          attrs: { src: "/images/lock.svg" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }